const tokens = {
  colors: {
    "bg.canvas": {
      default: "gray.25",
      _dark: "gray.950"
    },
    "bg.surface": {
      default: "white",
      _dark: "gray.900"
    },
    "bg.subtle": {
      default: "gray.50",
      _dark: "gray.800"
    },
    "bg.muted": {
      default: "gray.100",
      _dark: "gray.700"
    },
    "fg.default": {
      default: "gray.900",
      _dark: "white"
    },
    "fg.emphasized": {
      default: "gray.700",
      _dark: "gray.200"
    },
    "fg.muted": {
      default: "gray.600",
      _dark: "gray.300"
    },
    "fg.subtle": {
      default: "gray.500",
      _dark: "gray.400"
    },
    "fg.inverted": {
      default: "white",
      _dark: "gray.950"
    },
    "border.default": {
      default: "gray.200",
      _dark: "gray.800"
    },
    "border.emphasized": {
      default: "gray.300",
      _dark: "gray.700"
    },
    "border.active": {
      default: "gray.400",
      _dark: "gray.600"
    },
    "bg.accent.default": "brand.600",
    "bg.accent.subtle": "brand.500",
    "bg.accent.muted": "brand.400",
    "fg.accent.subtle": "brand.100",
    "fg.accent.muted": "brand.50",
    "fg.accent.default": "white",
    accent: {
      default: "brand.500",
      _dark: "brand.200"
    },
    success: {
      default: "green.500",
      _dark: "green.200"
    },
    error: {
      default: "red.500",
      _dark: "red.200"
    }
  },
  shadows: {
    xs: {
      default: "0px 0px 1px rgba(45, 55, 72, 0.05), 0px 1px 2px rgba(45, 55, 72,  0.1)",
      _dark: "0px 0px 1px rgba(13, 14, 20, 1), 0px 1px 2px rgba(13, 14, 20, 0.9)"
    },
    sm: {
      default: "0px 0px 1px rgba(45, 55, 72, 0.05), 0px 2px 4px rgba(45, 55, 72,  0.1)",
      _dark: "0px 0px 1px rgba(13, 14, 20, 1), 0px 2px 4px rgba(13, 14, 20, 0.9)"
    },
    md: {
      default: "0px 0px 1px rgba(45, 55, 72, 0.05), 0px 4px 8px rgba(45, 55, 72,  0.1)",
      _dark: "0px 0px 1px rgba(13, 14, 20, 1), 0px 4px 8px rgba(13, 14, 20, 0.9)"
    },
    lg: {
      default: "0px 0px 1px rgba(45, 55, 72, 0.05), 0px 8px 16px rgba(45, 55, 72,  0.1)",
      _dark: "0px 0px 1px rgba(13, 14, 20, 1), 0px 8px 16px rgba(13, 14, 20, 0.9)"
    },
    xl: {
      default: "0px 0px 1px rgba(45, 55, 72, 0.05), 0px 16px 24px rgba(45, 55, 72,  0.1)",
      _dark: "0px 0px 1px rgba(13, 14, 20, 1), 0px 16px 24px rgba(13, 14, 20, 0.9)"
    },
    focus: {
      default: "0 0 0 4px #EDF2F7",
      _dark: "0 0 0 4px #2D3748"
    }
  }
};

export { tokens as default };
