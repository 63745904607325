'use client';
import { jsx } from 'react/jsx-runtime';
import { cx } from '@chakra-ui/utils';
import { useStatStyles } from './stat.mjs';
import { forwardRef } from '../system/forward-ref.mjs';
import { chakra } from '../system/factory.mjs';

const StatLabel = forwardRef(
  function StatLabel2(props, ref) {
    const styles = useStatStyles();
    return /* @__PURE__ */ jsx(
      chakra.dt,
      {
        ref,
        ...props,
        className: cx("chakra-stat__label", props.className),
        __css: styles.label
      }
    );
  }
);
StatLabel.displayName = "StatLabel";

export { StatLabel };
